import Vueform from '@vueform/vueform'
import vueformConfig from '../../../vueform.config'

export default (async (app, router, store) => {
  app.use(Vueform, vueformConfig)

  store.commit('loading/ENABLE')

  await router.isReady()
  // Render app after router loading
  app.mount('#app')

  // Set router in store
  store.commit('router/SET_ROUTER', router)

  // Retrieve available locales
  await store.dispatch('locales/getStaticLocales')
  await store.dispatch('locales/getDynamicLocales')

  // Retrieve last versions of
  // privacy policy and terms and conditions
  await store.dispatch('terms/getLastTerms')

  // Handle authenticated state
  await store.dispatch('auth/getAuthenticatedUser')
  if (store.state.auth.authenticated) {
    await store.dispatch('auth/redirectAuthenticatedUser')
  }
  store.commit('loading/DISABLE')
})
