import { apiAxios } from '@shared/http/api'
import {
  handleResponseSuccess,
  handleResponseFailure,
  handleRequestSuccess,
  handleRequestFailure,
} from '@shared/http/interceptorsHandlers'

export default ((router, store) => {
  const options = {
    router,
    store,
  }

  apiAxios.interceptors.response.use(
    // Any status code that lie within the range of 2xx cause this function to trigger
    (response) => (handleResponseSuccess(response, options)),
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    (error) => (handleResponseFailure(error, options)),
  )

  apiAxios.interceptors.request.use(
    async (config) => (handleRequestSuccess(config, options)),
    (error) => (handleRequestFailure(error, options)),
  )
})
