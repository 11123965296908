import rollbar from '@shared/errors/rollbar'

export default ((app) => {
  // Log errors to Rollbar
  if (import.meta.env.VITE_APP_ENV === 'production' || import.meta.env.VITE_APP_ENV === 'staging') {
    app.config.errorHandler = (err) => {
      rollbar.error(err)
      throw err // rethrow
    }
  }
})
