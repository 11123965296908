export default {
  // Privacy policy url
  'privacyUrl': 'cookies',

  // Open the panel with this hashtag
  'hashtag': '#tarteaucitron',

  // Cookie name
  'cookieName': 'tarteaucitron',

  // Banner position (top - bottom)
  'orientation': 'middle',

  // Group services by category
  'groupServices': false,

  // Show the small banner on bottom right
  'showAlertSmall': false,

  // Show the cookie list
  'cookieslist': false,

  // Show a close X on the banner
  'closePopup': false,

  // Show cookie icon to manage cookies
  'showIcon': false,

  // Optional: URL or base64 encoded image
  // 'iconSrc': '',

  // BottomRight, BottomLeft, TopRight or TopLeft
  'iconPosition': 'BottomLeft',

  // Show a Warning if an ad blocker is detected
  'adblocker': false,

  // Show the deny all button
  'DenyAllCta': true,

  // Show the accept all button when highPrivacy on
  'AcceptAllCta': true,

  // HIGHLY RECOMMENDED Disable auto consent
  'highPrivacy': true,

  // If Do Not Track == 1, disallow all
  'handleBrowserDNTRequest': false,

  // Remove credit link
  'removeCredit': true,

  // Show more info link
  'moreInfoLink': false,

  // If false, the tarteaucitron.css file will be loaded
  'useExternalCss': false,

  // If false, the tarteaucitron.js file will be loaded
  'useExternalJs': false,

  // Change the default read more link
  'readmoreLink': '',

  // Show a message about mandatory cookies
  'mandatory': true,

  // Show the disabled "accept" button when mandatory on
  'mandatoryCta': true,
}
