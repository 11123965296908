import {
  fetchStaticLocales,
  fetchDynamicLocales,
} from '@shared/http/api'

const state = {
  dynamics: [],
  statics: [],
}

const mutations = {
  SET_DYNAMICS(state, value) {
    state.dynamics = value ?? []
  },
  SET_STATICS(state, value) {
    state.statics = value ?? []
  },
}

const actions = {
  async getStaticLocales({ commit }) {
    fetchStaticLocales()
      .then(({ data }) => {
        commit('SET_STATICS', data.data)
      })
  },
  async getDynamicLocales({ commit }) {
    fetchDynamicLocales()
      .then(({ data }) => {
        commit('SET_DYNAMICS', data.data)
      })
  },
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
