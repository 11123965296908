import tac from '@shared/cookies/tarteaucitron'
import config from '@shared/cookies/config'

export default (services = {}) => {
  document.addEventListener('DOMContentLoaded', () => {
    // Init library
    tac.init(config)

    // Config each service
    Object.entries(services).forEach((service) => {
      const [serviceName, serviceConfig] = service
      const serviceConfigName = `c_${serviceName}`

      // Add service's config
      tac.services[serviceConfigName] = serviceConfig

      // Services using cookies
      tac.job = tac.job || []
      tac.job.push(serviceConfigName)
    })
  })
}
