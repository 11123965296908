/* global SOURCE_VERSION */

import Rollbar from 'rollbar'

export default new Rollbar({
  accessToken: import.meta.env.VITE_ROLLBAR_CLIENT_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  transform: (payload) => {
    // Code source: https://docs.rollbar.com/docs/source-maps#using-source-maps-on-many-domains
    const trace = payload.body.trace
    const locRegex = /^(https?):\/\/[a-zA-Z0-9._-]+\.ugozer\.com(.*)/
    if (trace && trace.frames) {
      for (let i = 0; i < trace.frames.length; i += 1) {
        const filename = trace.frames[i].filename
        if (filename) {
          const m = filename.match(locRegex)
          trace.frames[i].filename = `${m[1]}://dynamichost${m[2]}`
        }
      }
    }
  },
  checkIgnore: (isUncaught, args) => (
    // Ignore HTTP errors from API, already sent to Rollbar server-side
    args[1]?.config?.baseURL?.startsWith(import.meta.env.VITE_API_BASE_URL)
      || !['production', 'staging'].includes(import.meta.env.VITE_APP_ENV)
  ),
  payload: {
    environment: import.meta.env.VITE_APP_ENV,
    client: {
      javascript: {
        code_version: SOURCE_VERSION,
        source_map_enabled: true,
        guess_uncaught_frames: false,
      },
    },
    server: {
      root: '../../../', // Project root relative to sourcemap files
    },
  },
})
