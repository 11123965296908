const MAX_FLASHES_NB = 4

const state = {
  flashes: [],
}

const mutations = {
  ADD_FLASH(state, payload) {
    const duplicateIndex = state.flashes.findIndex((flash) => (flash.message === payload.message))

    // Do not add duplicated
    if (duplicateIndex === -1) {
      // Only keep the last messages, and remove the old ones
      if (state.flashes.length >= MAX_FLASHES_NB) {
        const flashesToRemoveNb = (state.flashes.length + 1) - MAX_FLASHES_NB
        state.flashes.splice(0, flashesToRemoveNb)
      }

      // Add the new flash message
      state.flashes.push({ ...payload, timestamp: +new Date() })
    }
  },
  REMOVE_FLASH(state, payload) {
    const index = state.flashes.findIndex((flash) => flash.timestamp === payload.timestamp)
    state.flashes.splice(index, 1)
  },
}

const actions = {
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
